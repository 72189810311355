<template>
  <v-row>
    <v-col cols="1"></v-col>
    <v-col cols="10">
      <v-row
          v-for="(team, index) in teams"
          :key="index"
      >
        <v-col>
          <h1># {{ team.name }}</h1>
          <v-btn :href="team.league" target="_blank" class="ma-2">Tabelle/Spiele</v-btn>
          <v-btn :href="team.players" target="_blank" class="ma-2">Mannschaft</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    teams: [
      {
        name: '1. Herren - Stadtoberliga',
        league: 'https://leipzig-stadt.tischtennislive.de/default.aspx?L1=Ergebnisse&L2=TTStaffeln&L2P=17593',
        players: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17593&L3=Mannschaften&L3P=110097'
      },
      {
        name: '2. Herren - 2. Stadtliga',
        league: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17580',
        players: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17580&L3=Mannschaften&L3P=110098'
      },
      {
        name: '3. Herren - 1. Stadtklasse',
        league: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17582',
        players: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17582&L3=Mannschaften&L3P=110099'
      },
      {
        name: '4. Herren - 2. Stadtklasse',
        league: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17589',
        players: 'https://leipzig-stadt.tischtennislive.de/?L1=Ergebnisse&L2=TTStaffeln&L2P=17589&L3=Mannschaften&L3P=110100'
      },
    ],
  }),
}
</script>