<template>
  <v-row>
    <v-col cols="2"></v-col>
    <v-col cols="6">

      <v-card class="mt-2">
        <v-card-text>
          Willkommen auf der Website der Abteilung Tischtennis vom LSV Südwest e.V.! <br/>
          Uns findet ihr in der Turnhalle Breitschuhstraße in Großzschocher im Südwesten
          Leipzigs.<br/>
          <br/>
          Wir sind ca 50 aktive Mitglieder in unserer spielfreudigen und von engen
          persönlichen Verhältnissen geprägten Spielgemeinschaft, welche vier
          Männermannschaften zum aktuellen Spielbetrieb bis in die Stadtoberliga stellt. Es
          trainieren einige NachwuchsspielerInnen bei uns, und wir freuen uns in allen
          Altersklassen und Geschlechtern über mitspielende Gäste und Neuzugänge! Kommt
          gerne zu den offiziellen Trainingszeiten vorbei und sprecht uns an!
          <br/>
          <br/>
          Kontakt: (siehe <a href="#" v-on:click="$router.push({name: 'Impressum'})">Impressum</a>)
          <br/>
          <br/>

          <h2>Trainingszeiten</h2>
          <br />
          <ul>
            <li>Mittwoch
              <ul>
                <li>17:15 - 19:00 Kinder/Jugendliche</li>
                <li>19:00 - 21:30 Erwachsene</li>
              </ul>
            </li>
            <li>Freitag
              <ul>
                <li>19:30 - 21:30 Erwachsene</li>
              </ul>
            </li>
          </ul>

          <br />

          <h2>Spiellokal</h2>
          <br />
          Breitschuhstraße 17<br/>
          04249 Leipzig
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="2"></v-col>
  </v-row>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  data: () => ({
    model: 0,
    colors: [
      'primary', // Mannschaften
      'secondary',
      'yellow darken-2',
      'red',
    ],
  }),
}
</script>

<style>
/* unvisited link */
a:link {
  color: red;
}

/* visited link */
a:visited {
  color: red;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: red;
}

.v-card__text {
  font-size: 1.2em !important
}
</style>
