<template>
  <v-row>
    <v-col cols="2"></v-col>
    <v-col cols="8">
      <h2>Verein</h2>
      LSV Südwest e.V.<br />
      Abteilung Tischtennis<br />
      Windorfer Straße 63<br />
      04229 Leipzig

      <h2>Ansprechpartner</h2>
      <ul>
        <li>Abteilungsleiter
          <ul>
            <li>Mario Barke</li>
          </ul>
        </li>
        <li>Stellvertreter
          <ul>
            <li>Michael Garbe <code>michaelgarbe gmx de</code></li>
          </ul>
        </li>
        <li>Übungsleiter Schüler/Jugend
          <ul>
            <li>Eberhard Wagner <code>ebrewa arcor de</code></li>
          </ul>
        </li>
      </ul>

      <h2>Eintragung im Vereinsregister</h2>
      Registergericht: Amtsgericht Leipzig <br />
      Registernummer: 309

      <h2>Gesamtverein</h2>
      <a href="https://www.verein-lsv-suedwest.de">Webseite</a>
    </v-col>
    <v-col cols="2"></v-col>
  </v-row>
</template>

<script>

export default {
  name: 'Impressum',
  components: {}
}
</script>

<style>

</style>
