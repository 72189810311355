<template>
  <v-app>
    <app-header/>

    <v-main transition="slide-x-transition">
      <router-view/>
    </v-main>

  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader";

export default {
  components: {AppHeader}
}
</script>

<style>
</style>
